import React, { useCallback, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import { ColumnDefinition, useColumnDefinitions } from 'src/components/admin-table/hooks';
import Alert from 'src/components/alert/alert';
import { TypedFilterObj } from 'src/components/filter-bar/types';
import { withNotifications } from 'src/components/notification-container/notification-container';
import {
  defaultAccessoriesBarSpacing,
  defaultFilterSpacing,
  defaultPageTitleTransKeys,
} from 'src/components/organisations/constants';
import { ViewingContext } from 'src/components/organisations/organisation-profile/config';
import {
  useChecklistsAwaitingApproval,
  useViewingContext,
} from 'src/components/organisations/organisation-profile/hooks';
import { FIND_ORGANISATIONS } from 'src/components/organisations/organisations-queries';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import Spinner from 'src/components/spinner/spinner';
import { transformSortDirection } from 'src/components/table-controls/table-controls';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { OrganisationStatusEnum } from 'src/graphql-types/globalSaasTypes';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { OrganisationTypeEnum } from 'src/graphql-types/globalUstaTypes';
import {
  FindOrganisations,
  FindOrganisations_findOrganisations_results as OrganizationsListItem,
} from 'src/graphql-types/saas/FindOrganisations';
import { initQueryFilterTableHook, TypedUrlParamConfig } from 'src/hooks/use-query-filter-table';
import { TABLE_IDS } from 'src/utils/constants/tables';
import { buildFiltersConfig, insertRegionFilter } from 'src/utils/helper/filtersConfigUtils';
import ROUTES from 'src/utils/routes';

import * as styles from './organisations-list.module.less';
import { CreateConfigOptions, OrganisationsListProps } from './types';
import { getAllOrgStatusValues } from './utils';

const ChecklistAwaitingAlert = ({ count }: { count: number }) => {
  const { t } = useTranslation();

  return (
    <Alert
      title={t('organisationsModule.checklists.awaitingApprovalAlert.title')}
      icon={'md-alert-triangle'}
      variant="error"
      spacing={{ margins: { xs: 'top' }, base: 5 }}
    >
      <p className={styles.alertInfo}>
        {t('organisationsModule.checklists.awaitingApprovalAlert.info', {
          count,
        })}
      </p>
      <Link to={ROUTES.CHECKLISTS} className={styles.alertLink}>
        {t('organisationsModule.checklists.awaitingApprovalAlert.link')}
      </Link>
    </Alert>
  );
};

type TableItem = OrganizationsListItem & { id: string };

const useQFT = initQueryFilterTableHook<FindOrganisations, TableItem>(FIND_ORGANISATIONS);
const MIN_SEARCH_CHARS = 3;

const createFilters = ({ t, regionConfig, viewingContext, selectedFacilityId }: CreateConfigOptions) => {
  const defaultStatus = [OrganisationStatusEnum.ACTIVE];
  const statusOptions = [
    { value: OrganisationStatusEnum.ACTIVE, label: t('active') },
    { value: OrganisationStatusEnum.INACTIVE, label: t('inactive') },
    { value: OrganisationStatusEnum.DUPLICATE, label: t('duplicate') },
  ];

  const getStatusFilterPlaceholder = (val: string[]) => {
    if (!val.length) return t('any status');
    if (val.length === 1) return statusOptions.find((opt) => opt.value === val[0])?.label as string;

    return t('placeholderCount', { type: 'status', count: val.length });
  };

  const filters: (TypedFilterObj & { initialValue: any })[] = [
    {
      key: 'search',
      type: 'search',
      initialValue: '',
      props: {
        classNames: { wrapper: styles.searchWrapper, input: styles.search },
        id: 'organisation-search-input',
        placeholder: t('organisationsModule.filters.searchPlaceholderOrgList'),
        minCharsTooltip: { minChars: MIN_SEARCH_CHARS },
      },
      spacing: defaultFilterSpacing,
    },
    {
      key: 'status',
      type: 'multi-select',
      initialValue: defaultStatus,
      props: {
        placeholder: getStatusFilterPlaceholder,
        withChevron: true,
        options: statusOptions,
      },
      spacing: defaultFilterSpacing,
    },
  ];

  if (regionConfig) {
    const { insertAtIndex, transKeyDefaultOption, visibility } = regionConfig.filter;
    insertRegionFilter(filters, {
      defaultOptionLabel: t(transKeyDefaultOption),
      selectedFacilityId,
      insertAtIndex,
      viewingContext,
      visibility,
    });
  }

  return buildFiltersConfig(filters);
};

const createUrlParams = ({ regionConfig, viewingContext }: CreateConfigOptions) => {
  const paramsConfig: {
    [key: string]: TypedUrlParamConfig<string> | TypedUrlParamConfig<string[]>;
  } = {
    search: {
      type: 'STRING',
    } as TypedUrlParamConfig<string>,
    status: {
      type: 'ARRAY',
      toValue: (urlParam) => {
        const defaultValue = getAllOrgStatusValues();

        return Array.from(new Set(urlParam)).filter(
          (status) => status && defaultValue.includes(status as OrganisationStatusEnum),
        );
      },
    } as TypedUrlParamConfig<string[]>,
  };

  if (
    regionConfig &&
    (!regionConfig.filter.visibility || regionConfig.filter.visibility.includes(viewingContext as ViewingContext))
  ) {
    paramsConfig['region'] = {
      type: 'STRING',
    };
  }

  return paramsConfig;
};

const createConfigs = (options: CreateConfigOptions) => {
  return {
    filters: createFilters(options),
    urlParams: createUrlParams(options),
  };
};

const defaultColumnsConfig: ColumnDefinition[] = [
  {
    key: 'organisationName',
    header: 'name',
    transformer: {
      type: 'link',
      args: {
        accessor: 'organisationName',
        urlTemplate: '{{organisationId}}',
        accessorSecondaryInfo: 'csFriendlyId',
      },
    },
    sort: true,
  },
  {
    key: 'organisationType',
    header: 'type',
    transformer: {
      type: 'translation',
      args: {
        accessor: 'organisationType',
        namespace: 'organisationType',
      },
    },
    sort: false,
    columnToggle: { checked: true },
  },
  {
    key: 'status',
    header: 'status',
    transformer: { type: 'statusTag', args: { accessor: 'status', type: 'OrgStatusLabel' } },
    sort: false,
    columnToggle: { checked: true },
  },
  {
    key: 'externalId',
    header: 'External ID',
    columnToggle: { checked: false },
    sort: false,
  },
];

const OrganisationsList = ({
  regionConfig,
  columnsConfig = defaultColumnsConfig,
  orgType = OrganisationTypeEnum.CLUB,
  transKeyTitle = defaultPageTitleTransKeys.MANAGE_ORGANISATIONS,
}: OrganisationsListProps) => {
  const { t } = useTranslation();
  const selectedFacilityId = useOrgId();
  const { viewingContext } = useViewingContext() ?? {};
  const { data: checklistsAwaitingApproval, loading } = useChecklistsAwaitingApproval({
    onError: () => toast.error(t('organisationsModule.queryErrors.getChecklistsAwaitingApproval')),
  });
  const columns = useColumnDefinitions(columnsConfig);
  const { filters, urlParams } = useMemo(
    () => createConfigs({ t, regionConfig, viewingContext, selectedFacilityId }),
    [t, regionConfig, viewingContext, selectedFacilityId],
  );
  const getQueryVarsFromContext = useCallback(
    (ctx) => {
      const { search, status, region } = ctx.filters;
      const { limit, offset } = ctx.paging;
      const defaultSorting = {
        field: 'organisationName',
        direction: 'ASC',
      };
      const sort = ctx?.sorting?.length
        ? {
            field: ctx.sorting[0].property,
            direction: transformSortDirection(ctx.sorting[0].sortDirection),
          }
        : defaultSorting;
      const statusFilter = status.length
        ? {
            in: status,
          }
        : undefined;
      const searchFilter = typeof search === 'string' && search.length >= MIN_SEARCH_CHARS ? search : undefined;

      return {
        filter: {
          search: searchFilter,
          status: statusFilter,
          organisationType: { eq: orgType },
          parentOrganisationId: regionConfig && region,
        },
        sort,
        pageArgs: {
          limit,
          skip: offset,
        },
      };
    },
    [orgType, regionConfig],
  );

  const {
    components: { FilterBar, AccessoryBar, AdminTable, props },
    query: { error },
  } = useQFT(
    filters,
    {
      columns,
      accessoryBar: {
        items: [
          { type: 'pagination-info-top', position: 'left', props: {} },
          {
            type: 'column-toggle',
            position: 'right',
            props: {},
          },
        ],
        gap: 12,
        spacing: defaultAccessoriesBarSpacing,
      },
    },
    {
      columnSelectorId: TABLE_IDS.ORGANISATIONS_LIST,
      urlParams,
      mapFiltersToQueryOptions: ({ filters }) => {
        return {
          client: meshGatewayClient,
          getTotalItems: (data) => data?.findOrganisations?.total ?? 0,
          transformVariables: (vars: { limit?: number; offset?: number; sorts?: PropertySortInput[] }) => {
            const { limit, offset, sorts } = vars;

            return getQueryVarsFromContext({
              filters,
              paging: { limit, offset },
              sorting: sorts,
            });
          },
          fetchPolicy: 'no-cache',
        };
      },
      mapDataToTable: (data) => (data?.findOrganisations?.results as TableItem[]) ?? [],
      depsFilterConfig: [filters],
      depsTableConfig: [columns],
    },
  );

  if (loading) return <Spinner fullScreen />;

  return (
    <PageMaxWidth>
      <Grid>
        <PageHeader title={t(transKeyTitle)} />
      </Grid>
      {!!checklistsAwaitingApproval?.Total && <ChecklistAwaitingAlert count={checklistsAwaitingApproval.Total} />}
      <Panel extendedPadding>
        <FilterBar {...props.filterBar} defaultValues={{ ...props.filterBar.values, status: [] }} withClearBtn />
        <AccessoryBar {...props.accessoryBar} />
        <AdminTable
          {...props.adminTable}
          hideTopPaginationInfo
          error={error ? t('organisationsModule.queryErrors.findOrganisations') : undefined}
        />
      </Panel>
    </PageMaxWidth>
  );
};

export default withNotifications(OrganisationsList);
