import React, { useMemo } from 'react';

import Layout from 'src/components/layout/layout';
import { getClientProps } from 'src/components/organisations/client-props';
import OrganisationsList from 'src/components/organisations/organisations-list/organisations-list';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import IsHighSchoolState from 'src/components/util-components/is-high-school-state';
import Redirect from 'src/components/util-components/redirect';
import ROUTES from 'src/utils/routes';

const ManageOrganisations: React.FC = () => {
  const clientProps = useMemo(() => getClientProps()?.manageOrganisationsPage ?? {}, []);
  return (
    <Layout>
      <SEO title="Manage Organizations" />
      <PrivateRoute>
        <IsHighSchoolState>
          <IsHighSchoolState.Render ifTrue>
            <Redirect to={ROUTES.NOT_FOUND} />
          </IsHighSchoolState.Render>
          <IsHighSchoolState.Render ifFalse>
            <OrganisationsList {...clientProps} />
          </IsHighSchoolState.Render>
        </IsHighSchoolState>
      </PrivateRoute>
    </Layout>
  );
};

export default ManageOrganisations;
