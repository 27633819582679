import { TypedFilterObj } from 'src/components/filter-bar/types';
import { defaultFilterSpacing } from 'src/components/organisations/constants';
import { VIEWING_CONTEXT_MAP, ViewingContext } from 'src/components/organisations/organisation-profile/config';
import { Spacing } from 'src/hooks/spacing';

export type FilterConfig = TypedFilterObj & { initialValue: any };

export const buildFiltersConfig = (filters: FilterConfig[]) =>
  filters.reduce((acc, { key, ...rest }, index) => {
    acc[key] = { ...rest, position: index };
    return acc;
  }, {});

interface Options {
  insertAtIndex?: number;
  defaultOptionLabel: string;
  visibility?: ViewingContext[];
  spacing?: Spacing;
  viewingContext?: ViewingContext;
  selectedFacilityId: string;
}

export const insertRegionFilter = (
  filters: FilterConfig[],
  {
    insertAtIndex = 0,
    defaultOptionLabel,
    visibility,
    viewingContext,
    selectedFacilityId,
    spacing = defaultFilterSpacing,
  }: Options,
) => {
  const shouldDefaultToNationalRegion = viewingContext === VIEWING_CONTEXT_MAP.REGIONAL;

  const regionFilter: FilterConfig = {
    key: 'region',
    type: 'select:region',
    initialValue: shouldDefaultToNationalRegion ? selectedFacilityId : '',
    props: {
      defaultOptionLabel,
    },
    spacing,
    hide: visibility && !visibility.includes(viewingContext as ViewingContext),
  };

  filters.splice(insertAtIndex, 0, regionFilter);
};
